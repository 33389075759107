import React from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

import './styles.scss';

const Input = (props) => {
  const { errors, className, maxLength, id = null } = props;
  const ID = id || uuid();

  return (
    <div className={classnames('ba-input', className)}>
      <label htmlFor={ID}>
        <input id={ID} {...props} required maxLength={maxLength} />
      </label>
      {errors && <span className='ba-input__error'>{errors}</span>}
    </div>
  );
};

export default Input;
