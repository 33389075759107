export const address = {
  text: 'Украина, город Сумы <br/> пер. Институтский 34',
  map: 'https://www.google.com/maps?cid=10314990961781569077&hl=ru&_ga=2.224406300.72444813.1590148894-873224011.1584201946'
};

export const HOME_PAGE_URL = 'https://beautyapple.com.ua/';
export const SERVICES_PAGE_URL = `${HOME_PAGE_URL}services`;
export const ESHOP_PAGE_URL = `${HOME_PAGE_URL}beauty-shop`;
export const ANSWERS_PAGE_URL = `${HOME_PAGE_URL}faq`;
export const SCHOLL_PAGE_URL = `${HOME_PAGE_URL}school`;
