import React from 'react';
import classnames from 'classnames';
import styles from './footer.module.scss';
import Icon from 'components/UI/Icons';

import { HashLink as NavLink } from 'react-router-hash-link';

import Message from '../Elements/Message';
import { APP_THEMES } from 'redux-store/constants';

const FooterMobile = ({
  services,
  phones,
  address,
  openQuickQuestionPopup,
  appTheme,
}) => {
  return (
    <div className='ba-wrap'>
      <div
        className={classnames(styles.footer, {
          [styles.dark]: appTheme === APP_THEMES.dark,
        })}
      >
        <div className={classnames(styles.col, styles.wide)}>
          <h3 className='ba-h3'>
            <NavLink to={'/services'}>Услуги центра</NavLink>
          </h3>
          <ul className={classnames(styles.services)}>
            {services.map((i) => (
              <li key={i.title}>
                <NavLink to={'/category-service/' + i.path}>{i.title}</NavLink>
              </li>
            ))}
          </ul>
        </div>

        <div className={classnames(styles.col, styles.small)}>
          <div className={styles.eShop}>
            <h3 className='ba-h3'>
              <NavLink smooth to={'/beauty-shop'}>
                <Icon name='cart' />
                Бьютишоп
              </NavLink>
            </h3>
          </div>
          <div className={styles.feeds}>
            <h3 className='ba-h3'>
              <NavLink smooth to={'/testimonials'}>
                Отзывы
              </NavLink>
            </h3>
          </div>
          <div className='ba-footer__questions'>
            <h3 className='ba-h3'>
              <NavLink smooth to={'/faq'}>
                Ответы
              </NavLink>
            </h3>
            <h3 className='ba-h3'>
              <NavLink smooth to={'/school'}>
                Обучение
              </NavLink>
            </h3>
          </div>
        </div>

        <div className={styles.col}>
          <h3 className='ba-h3'>Контакты</h3>
          <div className='ba-footer__contacts-phones'>
            {phones.map((i) => (
              <p key={i}>
                <a href={`tel:${i}`}>{i}</a>
              </p>
            ))}
          </div>
          <div className='ba-footer__contacts-schedule'>
            <p>
              <time>09:00–19:00</time>
              <span>Пн-Сб</span>
            </p>
            <p>
              <time>Выходной</time>
              <span>Вс</span>
            </p>
          </div>
          <a
            href={address.map}
            target='_blank'
            rel='noopener noreferrer'
            className='ba-reg ba-footer__contacts-address'
            dangerouslySetInnerHTML={{
              __html: address.text,
            }}
          ></a>

          <p className={styles.policy}>
            <NavLink to={'/policy'} className={styles.policy_link}>
              Политика конфиденциальности
            </NavLink>
          </p>
        </div>

        <div className={classnames(styles.col, styles.middle)}>
          <Message openQuickQuestionPopup={openQuickQuestionPopup} />

          <div className='ba-footer__bottom'>
            <p className='ba-footer__bottom-copyright'>
              &#169; Beautyapple, {new Date().getFullYear()}
            </p>
            <p className='ba-footer__creator'>
              Сайт разработан{' '}
              <a
                href='https://crisp-studio.com'
                rel='noopener noreferrer'
                target='_blank'
              >
                crisp
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
