import {
  web
} from '../constants';

import school, {
  getServicesList,
  getFeedsList,
  getFAQList,
  getNewsList,
  getSpecialistsList,
  getCountContacts,
  getProducts,
  getPostsRequest,
  getMetatagsList,
} from 'api';

export const getServices = () => {
  return (dispatch) => {
    return getServicesList()
      .then(({
        data
      }) => {
        dispatch(saveServices(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getNews = () => {
  return (dispatch) => {
    return getNewsList()
      .then(({
        data
      }) => {
        dispatch(saveNews(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getPosts = () => {
  return (dispatch) => {
    return getPostsRequest()
      .then(({
        data
      }) => {
        dispatch(savePosts(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getFeeds = () => {
  return (dispatch) => {
    return getFeedsList()
      .then(({
        data
      }) => {
        dispatch(saveFeeds(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getFAQ = () => {
  return (dispatch) => {
    return getFAQList()
      .then(({
        data
      }) => {
        dispatch(saveFAQ(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getSpecialists = () => {
  return (dispatch) => {
    return getSpecialistsList()
      .then(({
        data
      }) => {
        dispatch(saveSpecialists(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getProductsList = () => {
  return (dispatch) => {
    return getProducts()
      .then(({
        data
      }) => {
        dispatch(saveProducts(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const countContacts = () => {
  return (dispatch) => {
    return getCountContacts()
      .then(({
        data
      }) => {
        dispatch(saveContactsCount(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getSchoolList = () => {
  return (dispatch) => {
    return school.getThemes()
      .then(({
        data
      }) => {
        dispatch(saveSchoolThemes(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getCitiesList = () => {
  return (dispatch) => {
    return school.getCities()
      .then(({
        data
      }) => {
        dispatch(saveSchoolCities(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getEventsList = () => {
  return (dispatch) => {
    return school.getEvents()
      .then(({
        data
      }) => {
        dispatch(saveSchoolEvents(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getSchoolFeeds = () => {
  return (dispatch) => {
    return school.getFeeds()
      .then(({
        data
      }) => {
        dispatch(saveSchoolFeeds(data));
      })
      .catch(error => {
        console.log(error);
      })
  }
}

export const getMetatags = () => {
  return (dispatch) => {
    return getMetatagsList()
      .then((res) => {
        dispatch(saveMetatags(res));
      })
  }
}

export const saveContactsCount = payload => ({
  type: web.SAVE_CONTACTS_COUNT,
  payload,
});

export const saveFeeds = payload => ({
  type: web.SAVE_FEEDS_LIST,
  payload,
});

export const saveFAQ = payload => ({
  type: web.SAVE_FAQ_LIST,
  payload,
});

export const saveNews = payload => ({
  type: web.SAVE_NEWS_LIST,
  payload,
});

export const saveServices = payload => ({
  type: web.SAVE_SERVICES_LIST,
  payload,
});

export const saveSpecialists = payload => ({
  type: web.SAVE_SPECIALISTS_LIST,
  payload,
});

export const saveProducts = payload => ({
  type: web.SAVE_PRODUCTS_LIST,
  payload,
});

export const savePosts = payload => ({
  type: web.SAVE_POSTS_LIST,
  payload,
});

export const savePromos = payload => ({
  type: web.SAVE_PROMOS_LIST,
  payload,
});

export const saveSchoolThemes = payload => ({
  type: web.SAVE_SCHOOL_THEMES_LIST,
  payload,
});

export const saveSchoolCities = payload => ({
  type: web.SAVE_SCHOOL_CITIES_LIST,
  payload,
});

export const saveSchoolEvents = payload => ({
  type: web.SAVE_SCHOOL_EVENTS_LIST,
  payload,
});

export const saveSchoolFeeds = payload => ({
  type: web.SAVE_SCHOOL_FEEDS,
  payload,
});

export const setAppTheme = payload => ({
  type: web.SET_APP_THEME,
  payload,
});

export const saveMetatags = payload => ({
  type: web.SAVE_METAGATS_LIST,
  payload,
});
