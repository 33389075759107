import React from 'react';
import './styles.scss';
import Icon from '../../UI/Icons/index';

import socials from 'mocks/socials';

const SocialList = () => {
  return (
    <ul className="ba-socials-list">
      {
        socials.map(i => 
          <li
            key={i.type}
          >
            <a
              href={ i.url }
              aria-label={`Go to ${i.type}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name={`social-${i.type}` } />
            </a>
          </li>
        )
      }
    </ul>
  )
};

export default SocialList;