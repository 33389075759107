import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import './index.scss';

import App from './App.jsx';
import store from './redux-store';

import URLs from './setts/urls';

// import ReactGA from 'react-ga';
// import ReactGA from "react-ga4";

import * as serviceWorker from './serviceWorker';

axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate';

// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : 'https://beauty-back.crisp-studio.com';
axios.defaults.baseURL = URLs.baseURL;

if ('ontouchstart' in document.documentElement) {
  document.body.style.cursor = 'pointer';
}

// process.env.NODE_ENV !== 'development' && ReactGA.initialize(process.env.REACT_APP_GTM_TOKEN);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV === 'development') {
  serviceWorker.unregister()
} else {
  serviceWorker.register();
}
