import React from 'react';
import classnames from 'classnames';
import styles from './imgOnline.module.scss';

const index = ({className, alt, ...propses}) => {
  return (
    <p className={classnames(styles.container, className)}>
      <img
        {...propses}
        alt={alt}
      />
    </p>
  );
};

export default index;