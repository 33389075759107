import React from 'react';
import InputMask from 'react-input-mask';

import { maskPhone as defaultMaskPhone } from 'setts/formats';
import Input from 'components/UI/Input';

const PhoneNumber = ({
  value = defaultMaskPhone,
  inputClasses,
  onChange,
  ...propses
}) => {
  const handlerInput = (e) => {
    const { name, value } = e.target;
    onChange({ target: { name, value: value.replace(/^\+/, '') } });
  };

  return (
    <InputMask
      mask='+38 (999) 999 99 99'
      placeholder={defaultMaskPhone}
      onChange={handlerInput}
      value={value}
      {...propses}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          type='tel'
          name='phone'
          className={inputClasses}
        />
      )}
    </InputMask>
  );
};

export default PhoneNumber;
