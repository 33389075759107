import throttle from 'lodash.throttle';
import isMobile from 'helpers/isMobile';
import 'helpers/isIOS';

const checkAddressBar = () => {
  if(navigator.platform.toUpperCase().indexOf('MAC')>=0) document.body.classList.add('mac-os');

  if(!isMobile || window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) return false;

  const heightWithBar = window.innerHeight;

  const checkAddressBar = () => {
    const action = heightWithBar < window.innerHeight ? 'remove' : 'add';
    document.body.classList[action]('address-bar-state');
  };

  const throttled = throttle(checkAddressBar, 300);
  checkAddressBar();
  window.addEventListener('resize', throttled);
};

export default checkAddressBar();
