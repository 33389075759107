import React from 'react';

export default [
  {
    path: '/',
    component: React.lazy(() =>
      import(/* webpackPreload: true */ '../views/Home'),
    ),
    // component: React.lazy(() => {
    //   return Promise.all([
    //     import(/* webpackPrefetch: true */ '../views/Home'),
    //     new Promise(resolve => setTimeout(resolve, 1000))
    //   ])
    //   .then(([moduleExports]) => moduleExports);
    // })
  },

  {
    path: '/about',
    component: React.lazy(() => import('../views/About')),
  },
  {
    path: '/services',
    component: React.lazy(() => import('../views/Services')),
  },
  {
    path: '/service/:service',
    component: React.lazy(() => import('../views/ServiceItemPage')),
  },
  {
    path: '/feedback/:procedure',
    component: React.lazy(() => import('../views/FeedbackForm')),
  },
  {
    path: '/testimonials',
    component: React.lazy(() => import('../views/Testimonials')),
  },
  {
    path: '/faq',
    component: React.lazy(() => import('../views/FaqPage')),
  },
  {
    path: '/policy',
    component: React.lazy(() => import('../views/Policy')),
  },
  {
    path: '/order-done',
    component: React.lazy(() => import('../views/OrderDone')),
  },
  {
    path: '/basket',
    component: React.lazy(() => import('../views/Basket')),
  },
  {
    path: '/product/:slug',
    component: React.lazy(() => import('../views/Product')),
  },
  {
    path: '/beauty-shop',
    component: React.lazy(() => import('../views/EShop')),
  },
  {
    path: '/category-service/:slug',
    component: React.lazy(() => import('../views/ServiceItemCategory')),
  },
  {
    path: '/answers/:slug',
    component: React.lazy(() => import('../views/Article')),
  },
  {
    path: '/promo/:slug',
    component: React.lazy(() => import('../views/Promo')),
  },

  {
    path: '/school',
    component: React.lazy(() => import('../views/School')),
  },
  {
    path: '/school/feedback/:procedure',
    component: React.lazy(() => import('../views/SchoolFeedback')),
  },
  {
    path: '/school/:slug',
    component: React.lazy(() => import('../views/SchoolCourse')),
  },
  {
    path: '/framework',
    component: React.lazy(() => import('../views/Framework')),
  },

  {
    path: null,
    component: React.lazy(() => import('../views/Page404')),
  },
];
