import React, { useState, useEffect } from 'react';

import { CSSTransition } from 'react-transition-group';

import GlobalEvents from 'setts/globalEvents';
import GenerateGlobalEvent from 'helpers/generateEvents';
import encodeHTML from 'helpers/encodeHTML';

import ThxNotification from 'components/Partials/ThxNotification';
import QuickQuestion from 'components/Partials/QuickQuestion';
import QuickRegister from 'components/Partials/QuickRegister';
import Underlay from 'components/Partials/Underlay';

import { sendFormRequest } from 'api';

const Popups = ({ device }) => {
  const [tnxNotification, setTnxNotification] = useState(
    {
      title: 'Спасибо за заявку',
      note: 'До встречи в Beautyapple!',
      visible: false
    }
  );

  const [isUnderlay, setUnderlay] = useState(false);
  const [isVisibleQuickQuestion, setVisibleQuickQuestion] = useState(false);
  const [isVisibleQuickRegister, setVisibleQuickRegister] = useState(false);

  const offUnderlay = () => {
    setUnderlay(false);
  }

  const onUnderlay = () => {
    setUnderlay(true);
  }

  const showThxNotification = ({ detail = {} }) => {
    const { title, note } = detail;
    onUnderlay();

    setTnxNotification(prevState => (
      {
        ...prevState,
        title,
        note,
        visible: true
      })
    );
  }

  const closeThxNotification = () => {
    setTnxNotification(prevState => (
      {
        ...prevState,
        visible: false
      })
    );
    offUnderlay();
  };

  const addListeners = () => {
    document.body.addEventListener(GlobalEvents.underlay.off, offUnderlay);
    document.body.addEventListener(GlobalEvents.underlay.on, onUnderlay);

    document.body.addEventListener(GlobalEvents.thxNotification, showThxNotification);

    document.body.addEventListener(GlobalEvents.quickQuestion, openQuickQuestion);
    document.body.addEventListener(GlobalEvents.quickRegister, openQuickRegister);
  };

  const removeListeners = () => {
    document.body.removeEventListener(GlobalEvents.underlay.off, offUnderlay);
    document.body.removeEventListener(GlobalEvents.underlay.on, onUnderlay);

    document.body.removeEventListener(GlobalEvents.thxNotification, showThxNotification);

    document.body.removeEventListener(GlobalEvents.quickQuestion, openQuickQuestion);
    document.body.removeEventListener(GlobalEvents.quickRegister, openQuickRegister);
  };

  const openQuickQuestion = () => {
    setVisibleQuickQuestion(true);
  };

  const openQuickRegister = () => {
    setVisibleQuickRegister(true);
  }

  const closeQuickQuestion = () => {
    setVisibleQuickQuestion(false);
  };

  const closeQuickRegister = () => {
    setVisibleQuickRegister(false);
  };

  // TODO:форма отправки с вопросом
  const QuickQuestionRequest = (data) => {
    const request = {
      type: 'standart',
      contact: {
        phone: data.phone.replace(/\D+/g, ""),
      },
      title: 'Вопрос Ольге',
      description: data.question
    };

    sendFormRequest(request)
      .then(res => {
        GenerateGlobalEvent(document.body, GlobalEvents.thxNotification, {
          title: 'Спасибо за заявку',
          note: 'Мы вам ответим при первой же возможности. Обычно это занимает до 1 дня.'
        });
      })
      .catch(() => {
        offUnderlay();
      });
  };

  // TODO:форма отправки быстрой регистрации на прием
  const sendQuickRegisterRequest = (data) => {
    const request = {
      type: 'procedure',
      contact: {
        phone: data.phone.replace(/\D+/g, ""),
        name: data.name
      },
      title: 'Заявка с сайта',
      description: encodeHTML(`Получен запрос на ${data.procedure === 'Консультация' ? 'консультацию' : 'процедуру'} с <a href="${window.location.href}">${window.location.href}</a>`)
    };

    sendFormRequest(request)
      .then(res => {
        GenerateGlobalEvent(document.body, GlobalEvents.thxNotification);
      })
  }

  useEffect(() => {
    addListeners();

    return () => {
      removeListeners();
    };
  });

  return (
    <div>
      <CSSTransition
        in={isUnderlay}
        classNames="underlay"
        timeout={{
          enter: 400,
          exit: 200,
        }}
        unmountOnExit
      >
        <Underlay />
      </CSSTransition>

      <CSSTransition
        in={isVisibleQuickRegister}
        classNames={`${device === 'mobile' ? 'slide-top' : 'fade'}`}
        timeout={{
          enter: 400,
          exit: 200
        }}
        unmountOnExit
      >
        <QuickRegister
          onClosePopup={closeQuickRegister}
          onSendForm={sendQuickRegisterRequest}
        />
      </CSSTransition>

      <CSSTransition
        in={isVisibleQuickQuestion}
        classNames={`${device === 'mobile' ? 'slide-top' : 'fade'}`}
        timeout={{
          enter: 400,
          exit: 200
        }}
        unmountOnExit
      >
        <QuickQuestion
          onClosePopup={closeQuickQuestion}
          onSendForm={QuickQuestionRequest}
        />
      </CSSTransition>

      {
        tnxNotification.visible &&
        <ThxNotification
          title={tnxNotification.title}
          note={tnxNotification.note}
          onClosePopup={closeThxNotification}
        />
      }
    </div>
  );
}

export default Popups;

// export default connect(
//   (state) => ({ popup: state.popup }),
//   (dipatch) => bindActionCreators({openPopup}, dipatch )
// )(Popups);