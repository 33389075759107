import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';

const GoogleAnalitics = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.ga) return;

    // window.ga('set', 'page', location.pathname + location.search);
    // window.ga('send', 'pageview');

    // ReactGA.set({ page: location.pathname });
    // ReactGA.pageview(location.pathname)

    // if (process.env.NODE_ENV !== 'development') {
    const UAId = window.ga.getAll()[0].get('trackingId');
    ReactGA.initialize(UAId);
    ReactGA.send("pageview");
    // }
  }, [location]);

  return <></>;
};

export default GoogleAnalitics;