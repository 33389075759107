import React, { Component } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import onClickOutside from 'react-onclickoutside';
import styles from './quickQuestion.module.scss';
import SwipeToClose from 'components/Layout/SwipeToClose';

import InputMask from 'react-input-mask';

import GenerateGlobalEvent from 'helpers/generateEvents';
import GlobalEvents from 'setts/globalEvents';
import Input from 'components/UI/Input';
import Button from '../../UI/Btn/index';
import Icon from 'components/UI/Icons';

import ImgOnline from 'components/Partials/ImgOnline';

import { maskPhone as defaultMaskPhone } from 'setts/formats';

import OlgaWebP from 'assets/img/olga.webp';
import OlgaJpg from 'assets/img/olga.jpg';
import validate from 'helpers/validators';

import TextareaAutosize from 'react-textarea-autosize';

const Olga = window.webPSupport ? OlgaWebP : OlgaJpg;

class QuickQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toSend: {
        phone: '',
        question: '',
      },
      isValid: {
        phone: false,
        question: false,
      },
      disabled: true,
    };

    this.close = props.onClosePopup;

    this.wrapScroll = React.createRef();
  }

  componentDidMount() {
    disableBodyScroll(this.wrapScroll.current, { reserveScrollBarGap: true });
    GenerateGlobalEvent(document.body, GlobalEvents.underlay.on);
  }

  componentWillUnmount() {
    enableBodyScroll(this.wrapScroll.current);
  }

  handleClickOutside() {
    this.close();
    GenerateGlobalEvent(document.body, GlobalEvents.underlay.off);
  }

  checkIsEnableSend() {
    const state = !Object.values(this.state.isValid)
      .some(i => !i);

    this.setState({
      disabled: !state
    });
  }

  validate({ name, value }) {
    let isValid = false;

    switch (name) {
      case 'phone': isValid = validate['phone'](value); break;
      case 'question': isValid = validate['required'](value); break;
      default: isValid = null;
    }

    if (isValid === null) return;

    this.handlerValidInput({ name, isValid });
  }

  handlerValidInput({ name, isValid }) {
    this.setState({
      isValid: {
        ...this.state.isValid,
        [name]: isValid
      }
    }, this.checkIsEnableSend);
  }

  handlerInput(e) {
    const { name, value } = e.target;

    this.setState({
      toSend: {
        ...this.state.toSend,
        [name]: value
      }
    });

    this.validate({ name, value });
  }

  handlerClick(e) {
    e.stopPropagation();
  }

  submitForm(e) {
    e.preventDefault();

    this.props.onSendForm(this.state.toSend);
    this.props.onClosePopup();
  }

  render() {
    const { disabled } = this.state;

    return (
      <div
        className={styles.container}
        ref={this.wrapScroll}
      >
        <SwipeToClose onSwipe={this.handleClickOutside.bind(this)}>
          <button
            type="button"
            onClick={this.handleClickOutside.bind(this)}
            className={styles.close}
          >
            <Icon name="close" />
          </button>
          <form
            className={styles.form}
            onSubmit={this.submitForm.bind(this)}
            id="quick-question"
          >
            <div className={styles.top}>
              <div className="ba-wrap">
                {/* <p className={styles.title}><b>Ответим на вопросы</b></p> */}
                {/* <Input
                name="email"
                placeholder="Email..."
                autoComplete="email"
                onChange={this.handlerInput.bind(this)}
              /> */}

                <InputMask
                  mask="+38 (999) 999 99 99"
                  placeholder={defaultMaskPhone}
                  onChange={this.handlerInput.bind(this)}
                >
                  {
                    (inputProps) => <Input
                      {...inputProps}
                      type="tel"
                      name="phone"
                      autoComplete="tel"
                      className={styles.input}
                    />
                  }
                </InputMask>

              </div>
            </div>

            <div className={styles.bottom}>
              <div className="ba-wrap">

                <div className={styles.bottom_question_inner}>
                  <p className={styles.bottom_question}>
                    <span>Чем могу вам помочь?</span>
                  </p>
                  <ImgOnline
                    src={Olga}
                    className={styles.img}
                  />
                </div>

                {/* <textarea
                name="question"
                rows="2"
                className={styles.textarea}
                placeholder="Привет! Меня интересует..."
                onChange={this.handlerInput.bind(this)}
              ></textarea> */}

                <TextareaAutosize
                  name="question"
                  minRows={2}
                  maxRows={6}
                  className={styles.textarea}
                  placeholder="Привет! Меня интересует..."
                  onChange={this.handlerInput.bind(this)}
                />

                <Button
                  mod="primary"
                  type="submit"
                  disabled={disabled}
                  className={styles.submit}
                >
                  <Icon name="send" />
                  <span>Отправить</span>
                </Button>
              </div>
            </div>
          </form>
        </SwipeToClose>
      </div>
    );
  }
}

export default onClickOutside(QuickQuestion);