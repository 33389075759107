import React from 'react';
import './styles.scss';

const baseClass = 'ba-switcher';

const Switcher = (props) => {
  const {
    keys,
    value,
    name,
    className,
    onChange
  } = props;

  const nameKey = parseInt(Date.now() + Math.random() * 100000);

  const classesReady = [baseClass, className].join(' ').trim();

  const handlerChange = (event) => {
    onChange({
      target: {
        name,
        value: event.target.value
      }
    });
  };

  return (
    <div className={classesReady}>
      { keys.map(item =>
        (<label key={ item.value }>
          <input
            type="radio"
            name={ nameKey }
            value={ item.value }
            checked={ value === item.value }
            onChange={ handlerChange }
          />
          <span>{ item.label }</span>
        </label>)
      ) }
    </div>
  )
}

export default Switcher;