import React, { useRef, useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const Underlay = () => {
  const wrapScroll = useRef(null);

  useEffect(() => {
    const ref = wrapScroll.current;
    disableBodyScroll(ref, { reserveScrollBarGap: true });

    return () => {
      enableBodyScroll(ref);
    }
  });

  return (
    <div
      ref={wrapScroll}
      className="ba-underlay"
    ></div>
  );
};

export default Underlay;