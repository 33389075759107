import React from 'react';
import classnames from 'classnames';
import Icons from '../../../assets/icons.svg';
import './styles.scss';

const Icon = ({ name, className }) => (
  <svg className={classnames(`ba-icon-${name}`, className)}>
    <use xlinkHref={`${Icons}#icon-${name}`} width='100%' height='100%' />
  </svg>
);

export default Icon;
