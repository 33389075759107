import axios from 'axios';
import { serialize } from 'object-to-formdata';

import urls from '../setts/urls';

export const getThemes = () => {
  const { method, url } = urls.getSchoolThemes;

  return axios({ url, method });
};

export const getCities = () => {
  const { method, url } = urls.getSchoolCities;

  return axios({ url, method });
};

export const getEvents = () => {
  const { method, url } = urls.getSchoolEvents;

  return axios({ url, method });
};

export const getFeeds = () => {
  const { method, url } = urls.getSchoolFeeds;

  return axios({ url, method });
};

export const sendSchoolFeed = (data) => {
  const { method, url } = urls.sendSchoolFeed;

  return axios({ url, method, data: serialize(data, { indices: true }) });
};

export const sendSchoolRequest = (data) => {
  const { method, url } = urls.sendSchoolRequest;

  return axios({ url, method, data: serialize(data, { indices: true }) });
};
