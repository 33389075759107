export default {
  scroll: {
    on: 'on-scroll',
    off: 'off-scroll'
  },
  underlay: {
    on: 'on-underlay',
    off: 'off-underlay',
    click: 'click-underlay'
  },
  thxNotification: 'thx-notication',
  quickQuestion: 'quick-question',
  quickRegister: 'quick-register',
  closePopups: 'close-popups',
  updateProductsInStore: 'update-product-store'
};