import React from 'react';
import Logo from 'components/Logo';

const Loading = () => {
  return (
    <div style={ {
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100vh',
        width: '100%',
        background: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 98px)',
        zIndex: 7
      } }>
      <div className="ba-wrap">
        <p className="ba-RC ba-center" style={{ fontSize: '72px', color: '#1B68A4', marginBottom: '12px' }}>Центр</p>
        <Logo />
      </div>
    </div>
  )
}

export default Loading;