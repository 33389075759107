import React from 'react';

import Button from 'components/UI/Btn/';
import Icon from 'components/UI/Icons';

import SocialList from 'components/Partials/SocialList';
import ImgOnline from 'components/Partials/ImgOnline';

import OlgaWebP from 'assets/img/olga.webp';
import OlgaJpg from 'assets/img/olga.jpg';

const Olga = window.webPSupport ? OlgaWebP : OlgaJpg;

const Message = ({ openQuickQuestionPopup }) => {
  return (
    <div className='ba-footer__message'>
      <div className='ba-footer__message-col'>
        <div className='ba-footer__message-left'>
          <h3 className='ba-h3'>Есть предложение?</h3>
          <p className='ba-reg'>
            Хотите связаться с главным врачом - просто напишите мне
          </p>
        </div>

        <ImgOnline src={Olga} alt='Olga' className='ba-footer__message-right' />
      </div>

      <p className='ba-footer__message-btn'>
        <Button onClick={openQuickQuestionPopup} mod='outline'>
          <Icon name='send' />
          <span>Написать Ольге</span>
        </Button>
      </p>

      <div className='ba-footer__socials'>
        <SocialList />
      </div>
    </div>
  );
};

export default Message;
