export default (value) => {
  if (!value) return false;

  if (Array.isArray(value)) {
    if (value.length === 0) return false;
    return true;
  }

  if (typeof value === 'string') {
    return value.trim() !== '';
  }

  return true;
};
