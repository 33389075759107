export const web = {
  SAVE_SERVICES_LIST: 'SAVE_SERVICES_LIST',
  SAVE_FEEDS_LIST: 'SAVE_FEEDS_LIST',
  SAVE_FAQ_LIST: 'SAVE_FAQ_LIST',
  SAVE_NEWS_LIST: 'SAVE_NEWS_LIST',
  SAVE_SPECIALISTS_LIST: 'SAVE_SPECIALISTS_LIST',
  SAVE_PRODUCTS_LIST: 'SAVE_PRODUCTS_LIST',
  SAVE_CONTACTS_COUNT: 'SAVE_CONTACTS_COUNT',
  SAVE_POSTS_LIST: 'SAVE_POSTS_LIST',
  SAVE_PROMOS_LIST: 'SAVE_PROMOS_LIST',
  SAVE_METAGATS_LIST: 'SAVE_METAGATS_LIST',

  SAVE_SCHOOL_THEMES_LIST: 'SAVE_SCHOOL_THEMES_LIST',
  SAVE_SCHOOL_CITIES_LIST: 'SAVE_SCHOOL_CITIES_LIST',
  SAVE_SCHOOL_EVENTS_LIST: 'SAVE_SCHOOL_EVENTS_LIST',
  SAVE_SCHOOL_FEEDS: 'SAVE_SCHOOL_FEEDS',

  SET_APP_THEME: 'SET_APP_THEME',
};

export const popups = {
  SET_OPENED_POPUP: 'SET_OPENED_POPUP',
};

export const APP_THEMES = {
  white: 'white',
  dark: 'dark',
};
