import {
  web
} from '../constants';
import orderByAvailability from '../../helpers/orderAvailabilityProduct';
import {
  APP_THEMES
} from '../constants/index';

const initialState = {
  servicesList: [],
  feedsList: [],
  faqList: [],
  newsList: [],
  specialists: [],
  products: [],
  brands: [],
  postsList: [],
  categories: [],
  school: {
    themes: [],
    cities: [],
    events: [],
  },
  metadata: [],
  contacts: 0,
  appTheme: APP_THEMES.white,
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case web.SAVE_SERVICES_LIST:
      return {
        ...state,
        ...{
          servicesList: action.payload
            .map((item) => ({
              original: {
                fall: '/img/services/list/original/New-service.jpg',
                webp: '/img/services/list/original/New-service.webp',
              },
              thumb: {
                fall: '/img/services/list/thumb/New-service.jpg',
                webp: '/img/services/list/thumb/New-service.webp',
              },
              ...item,
              type_content: 'service',
              children: item.children.filter((i) => i.is_visible),
            }))
            .filter((el) => el.children.length > 0),
        },
      };
    case web.SAVE_FEEDS_LIST:
      return {
        ...state,
        ...{
          feedsList: action.payload
            .filter((i) => i.is_visible)
            .map((item) => ({
              ...item,
              created_at_orig: new Date(`${item.created_at}`),
            }))
            .sort((a, b) => b.created_at_orig - a.created_at_orig),
        },
      };
    case web.SAVE_POSTS_LIST:
      return {
        ...state,
        ...{
          postsList: action.payload
            .filter((i) => i.is_active)
            .map((i) => ({
              ...i,
              type_content: 'post'
            }))
            .sort((a, b) => b.created_at - a.created_at),
        },
      };
    case web.SAVE_NEWS_LIST:
      return {
        ...state,
        ...{
          newsList: action.payload
            .filter((i) => i.is_active)
            .map((i) => ({
              ...i,
              type_content: 'news'
            }))
            .sort((a, b) => b.created_at - a.created_at),
        },
      };
    case web.SAVE_FAQ_LIST:
      return {
        ...state,
        ...{
          faqList: action.payload
            .filter((i) => i.is_visible)
            .map((i) => ({
              ...i,
              type_content: 'faq'
            })),
        },
      };
    case web.SAVE_PRODUCTS_LIST:
      return {
        ...state,
        ...{
          brands: action.payload.brands_list,
          categories: action.payload.categories,
          products: orderByAvailability(
            action.payload.products_list.filter((i) => i.is_visible),
          ),
        },
      };
    case web.SAVE_SPECIALISTS_LIST:
      return {
        ...state,
        ...{
          specialists: action.payload.map((item) => ({
            img: 'specialists/default',
            ...item,
          })),
        },
      };
    case web.SAVE_CONTACTS_COUNT:
      return {
        ...state,
        ...{
          contacts: action.payload,
        },
      };
    case web.SAVE_SCHOOL_THEMES_LIST:
      return {
        ...state,
        ...{
          school: {
            ...state.school,
            themes: action.payload,
          },
        },
      };
    case web.SAVE_SCHOOL_CITIES_LIST:
      return {
        ...state,
        ...{
          school: {
            ...state.school,
            cities: action.payload,
          },
        },
      };
    case web.SAVE_SCHOOL_EVENTS_LIST:
      return {
        ...state,
        ...{
          school: {
            ...state.school,
            events: action.payload,
          },
        },
      };
    case web.SAVE_SCHOOL_FEEDS:
      return {
        ...state,
        school: {
          ...state.school,
          feeds: action.payload,
        },
      };
    case web.SAVE_METAGATS_LIST:
      return {
        ...state,
        metadata: action.payload,
      };
    case web.SET_APP_THEME:
      if (state.appTheme === action.payload) {
        return state;
      }

      return {
        ...state,
        appTheme: action.payload,
      };
    default:
      return state;
  }
};

export default servicesReducer;
